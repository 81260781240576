/* This files contains for ALL gallery image types photos (more than 1) and overall description */
export const galleryText = [
    {
        id: 0,
        text: 'The charm of Hotel Sejour Mint is further enhanced by a Japanese traditional stove near the front and the many beautiful flower plants.',
        textJ: 'ホテルセジュールミントの魅力は、玄関近くにある日本の伝統的な囲炉裏と美しい花々によってさらに高められています。',
        textCh1: '靠近前台设有的一座传统的日式围炉和多处众多美丽的花卉植物使旅家酒店的魅力进一步提升。',
        textCh2: '靠近前台設有的一座傳統的日式圍爐和多處眾多美麗的花卉植物使旅家酒店的魅力進一步提升。'
    },
    {
        id: 1,
        text: 'A wide variety of flowers are planted around the property, creating a colorful display that blooms throughout different seasons. These vibrant flowers not only captivate people but also attract various animals.',
        textJ: '敷地内にはさまざまな種類の花が植えられており、季節ごとに咲き誇る美しい花々は人々だけでなく、他の動物たちも引き寄せています。',
        textCh1: '物业的建筑外种植了各种各样的花卉，在不同季节盛开的花朵吸引了人们和其他动物。',
        textCh2: '物業的建築外種植了各種各樣的花卉，不同季節盛開的花朵吸引了人們和其他動物。'
    },
    {
        id: 2,
        text: 'In the winter, people from all over the world flock to Hakuba for skiing. The convenient short walk to Goryu Escal Plaza (lift station) serves as a fantastic warm-up for guests before they hit the slopes for the day.',
        textJ: '冬には世界中の人々がスキーのために白馬に訪れます。五竜エスカルプラザ（リフト駅）までの短い散歩は、ゲストが一日のスキーを始める前の素晴らしいウォームアップとなります。',
        textCh1: '冬天，来自世界各地的人们纷纷来到白马滑雪。步行到五竜Escal广场（缆车站）只需很短的时间，这为客人在开始滑雪之前提供了一个很好的热身机会。',
        textCh2: '冬天，來自世界各地的人們會來白馬滑雪。步行到五竜Escal廣場（纜車站）只需很短的時間，這為客人在開始滑雪之前提供了一個很好的熱身機會。'
    },
    {
        id: 3,
        text: 'Spring is arguably the most breathtaking season in Hakuba. Pictures truly speak volumes.',
        textJ: '春はおそらく白馬で最も美しい季節です。白馬の美しい春は言葉では表せない。',
        textCh1: '春天可能是白马最美丽的季节。图片胜过千言万语。',
        textCh2: '春天可能是白馬最美麗的季節。圖片勝過千言萬語。'
    },
    {
        id: 4,
        text: 'Summer in Hakuba is a vibrant season, filled with energy, yet it manages to maintain a cool and comfortable atmosphere compared to other regions in Japan.',
        textJ: '白馬の夏は活気に満ちていますが、他の地域と比較して涼しく快適な環境が保たれています。',
        textCh1: '白马的夏天充满了活力，相比日本其他地区，它保持了凉爽舒适的环境。',
        textCh2: '白馬的夏天充滿了活力，相比日本其他地區，它保持了涼爽舒適的環境。'
    },
    {
        id: 5,
        text: 'Fall in Hakuba showcases a beautiful display of contrasting colors. It is the ideal time for hiking through the woods and mountains. ',
        textJ: '白馬の秋は対照的な色彩に彩られています。森や山でハイキングをするには最適な時期かもしれません。',
        textCh1: '白马的秋天充满了对比鲜明的色彩。这可能是在树林和山区远足的最佳时机。',
        textCh2: '白馬的秋天充滿了對比鮮明的色彩。這可能是在樹林和山區遠足的最佳時機。'
    },
    {
        id: 6,
        text: '',
        textJ: '',
        textCh1: '',
        textCh2: ''
    },
    {
        id: 7,
        text: '',
        textJ: '',
        textCh1: '',
        textCh2: ''
    },
    {
        id: 8,
        text: '',
        textJ: '',
        textCh1: '',
        textCh2: ''
    }

]
