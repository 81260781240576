/* This files contains for ALL gallery image types photos (more than 1) and overall description */
export const gallery = [
    {
    id: 0,
    category: 'gallery',
    name: 'Hotel',
    caption: '',
    images: [ 
        {
            id: 0,
            image: '/assets/images/gallery/hotel/gallery-hotel-0.jpg'
        },
        {
            id: 1,
            image: '/assets/images/gallery/hotel/gallery-hotel-1.jpg'
        },
        {
            id: 2,
            image: '/assets/images/gallery/hotel/gallery-hotel-2.jpg'
        },
        {
            id: 3,
            image: '/assets/images/gallery/hotel/gallery-hotel-3.jpg'
        },
        {
            id: 4,
            image: '/assets/images/gallery/hotel/gallery-hotel-4.jpg'
        },
        {
            id: 5,
            image: '/assets/images/gallery/hotel/gallery-hotel-5.jpg'
        },
        {
            id: 6,
            image: '/assets/images/gallery/hotel/gallery-hotel-6.jpg'
        },
        {
            id: 7,
            image: '/assets/images/gallery/hotel/gallery-hotel-7.jpg'
        },
        {
            id: 8,
            image: '/assets/images/gallery/hotel/gallery-hotel-8.jpg'
        },
        {
            id: 9,
            image: '/assets/images/gallery/hotel/gallery-hotel-9.jpg'
        },
        {
            id: 10,
            image: '/assets/images/gallery/hotel/gallery-hotel-10.jpg'
        },
        {
            id: 11,
            image: '/assets/images/gallery/hotel/gallery-hotel-11.jpg'
        }
    ]
    },
    {
        id: 1,
        category: 'gallery',
        name: 'Nature',
        caption: '',
        images: [ 
        {
            id: 0,
            image: '/assets/images/gallery/nature/gallery-nat-0.jpg'
        },
        {
            id: 1,
            image: '/assets/images/gallery/nature/gallery-nat-1.jpg'
        },
        {
            id: 2,
            image: '/assets/images/gallery/nature/gallery-nat-2.jpg'
        },
        {
            id: 3,
            image: '/assets/images/gallery/nature/gallery-nat-3.jpg'
        },
        {
            id: 4,
            image: '/assets/images/gallery/nature/gallery-nat-4.jpg'
        },
        {
            id: 5,
            image: '/assets/images/gallery/nature/gallery-nat-5.jpg'
        },
        {
            id: 6,
            image: '/assets/images/gallery/nature/gallery-nat-6.jpg'
        },
        {
            id: 7,
            image: '/assets/images/gallery/nature/gallery-nat-7.jpg'
        },
        {
            id: 8,
            image: '/assets/images/gallery/nature/gallery-nat-8.jpg'
        },
        {
            id: 9,
            image: '/assets/images/gallery/nature/gallery-nat-9.jpg'
        },
        {
            id: 10,
            image: '/assets/images/gallery/nature/gallery-nat-10.jpg'
        },
        {
            id: 11,
            image: '/assets/images/gallery/nature/gallery-nat-11.jpg'
        },
        {
            id: 12,
            image: '/assets/images/gallery/nature/gallery-nat-12.jpg'
        },
        {
            id: 13,
            image: '/assets/images/gallery/nature/gallery-nat-13.jpg'
        },
        {
            id: 14,
            image: '/assets/images/gallery/nature/gallery-nat-14.jpg'
        },
        {
            id: 15,
            image: '/assets/images/gallery/nature/gallery-nat-15.jpg'
        },        
        {
            id: 16,
            image: '/assets/images/gallery/nature/gallery-nat-16.jpg'
        },        
        {
            id: 17,
            image: '/assets/images/gallery/nature/gallery-nat-17.jpg'
        },        
        {
            id: 18,
            image: '/assets/images/gallery/nature/gallery-nat-18.jpg'
        },        
        {
            id: 19,
            image: '/assets/images/gallery/nature/gallery-nat-19.jpg'
        },        
        {
            id: 20,
            image: '/assets/images/gallery/nature/gallery-nat-20.jpg'
        },        
        {
            id: 21,
            image: '/assets/images/gallery/nature/gallery-nat-21.jpg'
        },        
        {
            id: 22,
            image: '/assets/images/gallery/nature/gallery-nat-22.jpg'
        },        
        {
            id: 23,
            image: '/assets/images/gallery/nature/gallery-nat-23.jpg'
        },        
        {
            id: 24,
            image: '/assets/images/gallery/nature/gallery-nat-24.jpg'
        },        
        {
            id: 25,
            image: '/assets/images/gallery/nature/gallery-nat-25.jpg'
        },        
        {
            id: 26,
            image: '/assets/images/gallery/nature/gallery-nat-26.jpg'
        },        
        {
            id: 27,
            image: '/assets/images/gallery/nature/gallery-nat-27.jpg'
        },        
        {
            id: 28,
            image: '/assets/images/gallery/nature/gallery-nat-28.jpg'
        },        
        {
            id: 29,
            image: '/assets/images/gallery/nature/gallery-nat-29.jpg'
        },        
        {
            id: 30,
            image: '/assets/images/gallery/nature/gallery-nat-30.jpg'
        },        
        {
            id: 31,
            image: '/assets/images/gallery/nature/gallery-nat-31.jpg'
        },        
        {
            id: 32,
            image: '/assets/images/gallery/nature/gallery-nat-32.jpg'
        },        
        {
            id: 33,
            image: '/assets/images/gallery/nature/gallery-nat-33.jpg'
        },        
        {
            id: 34,
            image: '/assets/images/gallery/nature/gallery-nat-34.jpg'
        },        
        {
            id: 35,
            image: '/assets/images/gallery/nature/gallery-nat-35.jpg'
        },        
        {
            id: 36,
            image: '/assets/images/gallery/nature/gallery-nat-36.jpg'
        },        
        {
            id: 37,
            image: '/assets/images/gallery/nature/gallery-nat-37.jpg'
        },        
        {
            id: 38,
            image: '/assets/images/gallery/nature/gallery-nat-38.jpg'
        },        
        {
            id: 39,
            image: '/assets/images/gallery/nature/gallery-nat-39.jpg'
        },        
        {
            id: 40,
            image: '/assets/images/gallery/nature/gallery-nat-40.jpg'
        },        
        {
            id: 41,
            image: '/assets/images/gallery/nature/gallery-nat-41.jpg'
        }
        ]
    },
    {
        id: 2,
        category: 'gallery',
        name: 'Winter',
        caption: '',
        images: [ 
        {
            id: 0,
            image: '/assets/images/gallery/winter/gallery-win-0.jpg'
        },
        {
            id: 1,
            image: '/assets/images/gallery/winter/gallery-win-1.jpg'
        },
        {
            id: 2,
            image: '/assets/images/gallery/winter/gallery-win-2.jpg'
        },
        {
            id: 3,
            image: '/assets/images/gallery/winter/gallery-win-3.jpg'
        },
        {
            id: 4,
            image: '/assets/images/gallery/winter/gallery-win-4.jpg'
        },
        {
            id: 5,
            image: '/assets/images/gallery/winter/gallery-win-5.jpg'
        },
        {
            id: 6,
            image: '/assets/images/gallery/winter/gallery-win-6.jpg'
        },
        {
            id: 7,
            image: '/assets/images/gallery/winter/gallery-win-7.jpg'
        },
        {
            id: 8,
            image: '/assets/images/gallery/winter/gallery-win-8.jpg'
        },
        {
            id: 9,
            image: '/assets/images/gallery/winter/gallery-win-9.jpg'
        },
        {
            id: 10,
            image: '/assets/images/gallery/winter/gallery-win-10.jpg'
        },
        {
            id: 11,
            image: '/assets/images/gallery/winter/gallery-win-11.jpg'
        }
        ]
    },
    {
        id: 3,
        category: 'gallery',
        name: 'Spring',
        caption: '',
        images: [ 
        {
            id: 0,
            image: '/assets/images/gallery/spring/gallery-spr-0.jpg'
        },
        {
            id: 1,
            image: '/assets/images/gallery/spring/gallery-spr-1.jpg'
        },
        {
            id: 2,
            image: '/assets/images/gallery/spring/gallery-spr-2.jpg'
        },
        {
            id: 3,
            image: '/assets/images/gallery/spring/gallery-spr-3.jpg'
        },
        {
            id: 4,
            image: '/assets/images/gallery/spring/gallery-spr-4.jpg'
        },
        {
            id: 5,
            image: '/assets/images/gallery/spring/gallery-spr-5.jpg'
        },
        {
            id: 6,
            image: '/assets/images/gallery/spring/gallery-spr-6.jpg'
        },
        {
            id: 7,
            image: '/assets/images/gallery/spring/gallery-spr-7.jpg'
        },
        {
            id: 8,
            image: '/assets/images/gallery/spring/gallery-spr-8.jpg'
        },
        {
            id: 9,
            image: '/assets/images/gallery/spring/gallery-spr-9.jpg'
        },
        {
            id: 10,
            image: '/assets/images/gallery/spring/gallery-spr-10.jpg'
        },        {
            id: 11,
            image: '/assets/images/gallery/spring/gallery-spr-11.jpg'
        },
        {
            id: 12,
            image: '/assets/images/gallery/spring/gallery-spr-12.jpg'
        },
        {
            id: 13,
            image: '/assets/images/gallery/spring/gallery-spr-13.jpg'
        },
        {
            id: 14,
            image: '/assets/images/gallery/spring/gallery-spr-14.jpg'
        },
        {
            id: 15,
            image: '/assets/images/gallery/spring/gallery-spr-15.jpg'
        },
        {
            id: 16,
            image: '/assets/images/gallery/spring/gallery-spr-16.jpg'
        },
        {
            id: 17,
            image: '/assets/images/gallery/spring/gallery-spr-17.jpg'
        }
        ]
    },
    {
        id: 4,
        category: 'gallery',
        name: 'Summer',
        caption: '',
        images: [ 
        {
            id: 0,
            image: '/assets/images/gallery/summer/gallery-sum-0.jpg'
        },
        {
            id: 1,
            image: '/assets/images/gallery/summer/gallery-sum-1.jpg'
        },
        {
            id: 2,
            image: '/assets/images/gallery/summer/gallery-sum-2.jpg'
        },
        {
            id: 3,
            image: '/assets/images/gallery/summer/gallery-sum-3.jpg'
        },
        {
            id: 4,
            image: '/assets/images/gallery/summer/gallery-sum-4.jpg'
        },
        {
            id: 5,
            image: '/assets/images/gallery/summer/gallery-sum-5.jpg'
        },
        {
            id: 6,
            image: '/assets/images/gallery/summer/gallery-sum-6.jpg'
        },
        {
            id: 7,
            image: '/assets/images/gallery/summer/gallery-sum-7.jpg'
        },
        {
            id: 8,
            image: '/assets/images/gallery/summer/gallery-sum-8.jpg'
        },
        {
            id: 9,
            image: '/assets/images/gallery/summer/gallery-sum-9.jpg'
        },
        {
            id: 10,
            image: '/assets/images/gallery/summer/gallery-sum-10.jpg'
        },
        {
            id:11,
            image: '/assets/images/gallery/summer/gallery-sum-11.jpg'
        },
        {
            id: 12,
            image: '/assets/images/gallery/summer/gallery-sum-12.jpg'
        },
        {
            id:  13,
            image: '/assets/images/gallery/summer/gallery-sum-13.jpg'
        },
        {
            id: 14,
            image: '/assets/images/gallery/summer/gallery-sum-14.jpg'
        },
        {
            id: 15,
            image: '/assets/images/gallery/summer/gallery-sum-15.jpg'
        },
        {
            id: 16,
            image: '/assets/images/gallery/summer/gallery-sum-16.jpg'
        },
        {
            id: 17,
            image: '/assets/images/gallery/summer/gallery-sum-17.jpg'
        }
        ]
    },
    {
        id: 5,
        category: 'gallery',
        name: 'Fall',
        caption: '',
        images: [ 
        {
            id: 0,
            image: '/assets/images/gallery/fall/gallery-fall-0.jpg'
        },
        {
            id: 1,
            image: '/assets/images/gallery/fall/gallery-fall-1.jpg'
        },
        {
            id: 2,
            image: '/assets/images/gallery/fall/gallery-fall-2.jpg'
        },
        {
            id: 3,
            image: '/assets/images/gallery/fall/gallery-fall-3.jpg'
        },
        {
            id: 4,
            image: '/assets/images/gallery/fall/gallery-fall-4.jpg'
        },
        {
            id: 5,
            image: '/assets/images/gallery/fall/gallery-fall-5.jpg'
        },
        {
            id: 6,
            image: '/assets/images/gallery/fall/gallery-fall-6.jpg'
        },
        {
            id: 7,
            image: '/assets/images/gallery/fall/gallery-fall-7.jpg'
        },
        {
            id: 8,
            image: '/assets/images/gallery/fall/gallery-fall-8.jpg'
        },
        {
            id: 9,
            image: '/assets/images/gallery/fall/gallery-fall-9.jpg'
        },
        {
            id: 10,
            image: '/assets/images/gallery/fall/gallery-fall-10.jpg'
        },
        {
            id: 11,
            image: '/assets/images/gallery/fall/gallery-fall-11.jpg'
        },
        {
            id: 12,
            image: '/assets/images/gallery/fall/gallery-fall-12.jpg'
        },
        {
            id: 13,
            image: '/assets/images/gallery/fall/gallery-fall-13.jpg'
        },
        {
            id: 14,
            image: '/assets/images/gallery/fall/gallery-fall-14.jpg'
        },
        {
            id: 15,
            image: '/assets/images/gallery/fall/gallery-fall-15.jpg'
        },
        {
            id: 16,
            image: '/assets/images/gallery/fall/gallery-fall-16.jpg'
        },
        {
            id: 17,
            image: '/assets/images/gallery/fall/gallery-fall-17.jpg'
        },
        {
            id: 18,
            image: '/assets/images/gallery/fall/gallery-fall-18.jpg'
        },
        {
            id: 19,
            image: '/assets/images/gallery/fall/gallery-fall-19.jpg'
        },
        {
            id: 20,
            image: '/assets/images/gallery/fall/gallery-fall-20.jpg'
        },
        {
            id: 21,
            image: '/assets/images/gallery/fall/gallery-fall-21.jpg'
        }
        ]
    }
]