/* This files contains for ALL room types the rooms photos (more than 1) and overall description */
/* This file is used to render the booking details whereas roomTypes1.js cis used for display pic for website */
/* The details include: standard room type has two subtypes, with 2 and 3 beds respectively. Same is true for family type */
export const roomType2 = [
    {
    id: 0,
    category: 'rooms',
    type: 'twin',
    dbId: 4,
    name: 'Twin Rooms',
    nameJ: 'ツイン',
    caption: 'A Twin Room has two single beds and an en suite bathroom/toilet with a bathtub and shower.',
    captionJ: 'ツインルームにはシングルベッドが2つあり、専用のバスルーム/トイレがついており、バスタブとシャワーがあります。',
    captionCh1: '双人房拥有两张单人床，以及带浴缸和淋浴的套间浴室/卫生间。',
    captionCh2: '雙人房擁有兩張單人床，以及帶浴缸和淋浴的套間浴室/衛生間。',
    numGuests: 2,
    images: [ 
        {
            id: 0,
            image: '/assets/images/rooms/twin/twin0.jpg'
        },
        {
            id: 1,
            image: '/assets/images/rooms/twin/twin1.jpg'
        },
        {
            id: 2,
            image: '/assets/images/rooms/twin/twin2.jpg'
        },
        {
            id: 3,
            image: '/assets/images/rooms/twin/twin3.jpg'
        },
        {
            id: 4,
            image: '/assets/images/rooms/twin/twin4.jpg'
        },
        {
            id: 5,
            image: '/assets/images/rooms/twin/twin5.jpg'
        }
    ]
    },
    {
        id: 1,
        category: 'rooms',
        type: 'triple',
        dbId: 1,
        name: 'Triple Rooms',
        nameJ: 'トリプル',
        caption: 'A Triple Room has three single beds and an en suite bathroom/toilet with a bathtub and shower. ',
        captionJ: 'トリプルルームにはシングルベッドが3つあり、専用のバスルーム/トイレがついており、バスタブとシャワーがあります。',
        captionCh1: '三人间有三张单人床，以及带浴缸和淋浴的套间浴室/卫生间。',
        captionCh2: '三人間有三張單人床，以及帶浴缸和淋浴的套間浴室/廁所。',
        numGuests: 3,
        images: [ 
            {
                id: 0,
                image: '/assets/images/rooms/triple/triple1.jpg'
            },
            {
                id: 1,
                image: '/assets/images/rooms/triple/triple2.jpg'
            },
            {
                id: 2,
                image: '/assets/images/rooms/triple/triple3.jpg'
            },
            {
                id: 3,
                image: '/assets/images/rooms/triple/triple4.jpg'
            }
        ]
        },
    {
        id: 2,
        category: 'rooms',
        type: 'japanlarge',
        dbId: 2,
        name: 'Japanese Style Large Room',
        nameJ: '和室スーぺリア',
        caption: 'The Japanese Large room is a traditional style tatami room with futons and an en suite bathroom/toilet with a bathtub and shower. The room can accommodate comfortably three guests.',
        captionJ: '和室スーぺリアは、広々とした畳の和室で、敷布団があり、専用のバスルーム/トイレがついており、バスタブとシャワーがあります。この部屋は快適に3名様まで宿泊可能です。',
        captionCh1: '日式大房间是一间传统风格的榻榻米房间，配有床垫，带浴缸和淋浴的套间浴室/厕所。该房间舒适地容纳三名客人。',
        captionCh2: '日式大房間是一間傳統風格的榻榻米房間，配有床墊，帶浴缸和淋浴的套間浴室/廁所。該房間舒適地容納三名客人。',
        numGuests: 5,
        images: [ 
            {
                id: 0,
                image: '/assets/images/rooms/japanlarge/jpLg1.jpg'
            },
            {
                id: 1,
                image: '/assets/images/rooms/japanlarge/jpLg2.jpg'
            }
        ]
    },
    {
        id: 3,
        category: 'rooms',
        type: 'suite',
        dbId: 5,
        name: 'Mixed Japanese and Western Style Suite',
        nameJ: '和洋室',
        caption: 'The Mixed Family Suite has a western room with three single beds and a Japanese style tatami room with futons. It also has an en suite bathroom/toilet with a bathtub and shower.',
        captionJ: '和洋折衷のスイートルームには５人が宿泊できます。洋室にはベッドが3台あり、和室には2つの布団が置かれています。専用のバスルーム/トイレがついており、バスタブとシャワーがあります。',
        captionCh1: '混合式家庭套房包括一间带有三张单人床的西式房间和一间铺有床垫的日式榻榻米房间。此套房还配备了带浴缸和淋浴的套间浴室/卫生间。',
        captionCh2: '混合式家庭套房包括一間帶有三張單人床的西式房間和一間鋪有床墊的日式榻榻米房間。此套房還配備了帶浴缸和淋浴的套間浴室/廁所。',
        numGuests: 7,
        images: [ 
            {
                id: 0,
                image: '/assets/images/rooms/suites/suite1.jpg'
            },
            {
                id: 1,
                image: '/assets/images/rooms/suites/suite2.jpg'
            },
            {
                id: 2,
                image: '/assets/images/rooms/suites/suite3.jpg'
            }
        ]
    },
    {
        id: 4,
        category: 'rooms',
        type: 'group',
        dbId: 3,
        name: 'Group Room',
        nameJ: '大部屋',
        caption: 'The group room is a large tatami room with futons with shared toilets and Japanese style bathtubs. The room is best for group travelers and can accommodate up to ten guests.',
        captionJ: '大部屋は、広々とした畳の和室で、敷布団があります。トイレとお風呂は他の顧客と共用の施設を使えます。この部屋は、グループ旅行者向けで最大10名まで宿泊可能です。',
        captionCh1: '群组房是一间宽敞的榻榻米房间，配备了床垫，共用卫生间和日式风格的浴缸。该房间最适合团体旅行者，最多可容纳十名客人。',
        captionCh2: '群組房是一間寬敞的榻榻米房間，配備了床墊，共用衛生間和日式風格的浴缸。該房間最適合團體旅行者，最多可容納十名客人。',
        numGuests: 10,
        images: [ 
            {
                id: 0,
                image: '/assets/images/rooms/group/group1.jpg'
            },
            {
                id: 1,
                image: '/assets/images/rooms/group/group2.jpg'
            }
        ]
    },
    {
        id: 5,
        category: 'rooms',
        type: 'maisonette',
        dbId: 7,
        name: 'Maisonette Room',
        nameJ: 'メソネット室',
        caption: 'The Maisonette Room has two single beds and an en suite bathroom/toilet with a bathtub and shower. ',
        captionJ: 'メソネット室にはシングルベッドが2つあり、専用のバスルーム/トイレがついており、バスタブとシャワーがあります。',
        captionCh1: '复式房间有两张单人床，带有浴缸和淋浴的套间浴室/卫生间。',
        captionCh2: '複式房間配有兩張單人床，並設有帶浴缸和淋浴的套間浴室/衛生間。',
        numGuests: 5,
        images: [ 
            {
                id: 0,
                image: '/assets/images/rooms/maisonette/maisonette1.jpg'
            },
            {
                id: 1,
                image: '/assets/images/rooms/maisonette/maisonette2.jpg'
            }
        ]
    },
    {
        id: 6,
        category: 'rooms',
        type: 'japanstd',
        dbId: 6,
        name: 'Japanese Style Standard Room',
        nameJ: '和室STD',
        caption: 'The Japanese Standard room is a traditional style tatami room with futons and an en suite bathroom/toilet with a bathtub and shower.　The room can accommodate two people. ',
        captionJ: '和室STDは、畳の和室で、敷布団があり、専用のバスルーム/トイレがついており、バスタブとシャワーがあります。この部屋は2人まで宿泊可能です。',
        captionCh1: '日式标准房是一间传统风格的榻榻米房间，配有床垫，带浴缸和淋浴的套间浴室/卫生间。该房间可容纳两人。',
        captionCh2: '和式標準房是一間傳統風格的榻榻米房間，配有床墊，帶浴缸和淋浴的套間浴室/廁所。該房間可容納兩人。',
        numGuests: 3,
        images: [ 
            {
                id: 0,
                image: '/assets/images/rooms/japanstd/jpStd1.jpg'
            },
            {
                id: 1,
                image: '/assets/images/rooms/japanstd/jpStd2.jpg'
            }
        ]
    }
]