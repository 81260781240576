/* the text below is for the overall description of all rooms. Each id is one paragraph */
export const roomsMainText = [ 
	{
		id: 0,
		category: 'rooms',
		name: 'Rooms Description',
		text: 'Hotel Sejour Mint offers a variety of room types to cater to the diverse needs of individual, family, and group travelers. Guests can choose from Western-style rooms, Japanese-style rooms, or suite rooms with a combination of both. Each room is equipped with a range of amenities to ensure a comfortable stay.',
		textJ: 'ホテルセジュールミントは、個人、家族、グループ旅行者の多様なニーズに対応するために、さまざまなタイプの客室を提供しています。ゲストはウェスタンスタイルの部屋、和室、または両方の組み合わせを備えたスイートルームから選ぶことができます。各部屋には快適な滞在を保証するためのさまざまなアメニティが備わっています。',
		textCh1: '旅家酒店提供多种房型，以满足个人、家庭和团体旅行者的多样化需求。客人可以选择西式客房、日式客房，或者结合两者的套房。每间客房都配备了各种设施，以确保舒适的住宿体验。',
		textCh2: '旅家酒店提供多種房型，以滿足個人、家庭和團體旅行者的多樣化需求。客人可以選擇西式客房、日式客房，或者結合兩者的套房。每間客房都配備了各種設施，以確保舒適的住宿體驗。'
	},
	{
		id: 1,
		category: 'rooms',
		name: 'Amenities',
		text: 'The office-grade WiFi system provides coverage in all guest rooms as well as public areas. Additionally, all toilets in the property are equipped with electronic bidet functionality and heatable seats. Each guest room is furnished with an internet-enabled TV set, allowing guests to stream their favorite shows.',
		textJ: 'オフィスグレードのWiFiシステムは全てのゲストルームと公共エリアをカバーしています。物件内の全てのトイレには暖房可能なウォシュレットが備わっています。全てのゲストルームにはインターネット対応のテレビがあり、ゲストはお好きな番組をストリーミングできます。',
		textCh1: '办公室级别的WiFi系统覆盖所有客房和公共区域。物业内所有的厕所都配备了可以加热的暖水清洗座椅。所有客房都配备了可连接互联网的电视，让客人可以观看他们喜爱的节目。',
		textCh2: '辦公室級別的WiFi系統覆蓋所有客房和公共區域。物業內所有的廁所都配備了可以加熱的暖水清洗座椅。所有客房都配備了可連接互聯網的電視，讓客人可以觀看他們喜愛的節目。'
	},
	{
		id: 2,
		category: 'rooms',
		name: 'Amenities',
		text: 'Every guest room, with the exception of the Japanese Style Group Room, is equipped with a private bathroom complete with a bathtub, shower, and toilet. Additionally, each guest room is furnished with essential amenities such as a safebox, an electric kettle, a hair dryer, a television, and a refrigerator. ',
		textJ: 'すべての客室は、日本式グループルームを除いて、バスタブ、シャワー、トイレを備えた専用バスルームが完備されています。さらに、各客室にはセーフボックス、電気ポット、ドライヤー、テレビ、冷蔵庫などの必需品が完備されています。',
		textCh1: '除了日式风格团体房之外，每个客房都配备有带浴缸、淋浴和卫生间的私人浴室。此外，每个客房还设有保险箱、电热水壶、吹风机、电视和冰箱等基本设施。',
		textCh2: '除了日式風格團體房之外，每個客房都配備有帶浴缸、淋浴和衛生間的私人浴室。此外，每個客房還設有保險箱、電熱水壺、吹風機、電視和冰箱等基本設施。'
	},
	{
		id: 3,
		category: 'rooms',
		name: 'Amenities',
		text: 'Japanese style baths (ofuro) for both men and women are available for all guests, in addition to shared toilets and other amenities. ',
		textJ: '全てのお客様には、男性と女性の別々の施設を備えた日本式風呂（お風呂）が共用トイレやその他の設備とともに利用可能です。',
		textCh1: '除了共用的洗手间和其他设施之外，我们为所有客人提供了男女分开的日式浴室.',
		textCh2: '除了共用的洗手間和其他設施之外，我們為所有客人提供了男女分開的日式浴室.'
	}
]